<template>
  <div v-if="dialog && user">
    <v-dialog
      :value="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="50%"
    >
      <div class="card">
        <div class="modal-header">
          <h5 class="modal-title" id="formModal">
            {{ !user._id ? $t("title.create_user") : $t("title.update_user") }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('onCloseDialog')"
          >
            <span @click="$emit('onCloseDialog')" aria-hidden="true">×</span>
          </button>
        </div>

        <Loading :visible="loading" />
        <div class="card-body">
          <div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--username-->
              <div class="form-group">
                <label>Username</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <v-text-field
                    v-model="user.user_name"
                    pattern="[A-Za-z]+"
                    :rules="rules.user_name"
                    :disabled="user._id ? true : false"
                    style="background:#fdfdff!important"
                    class="form-control mt-0 px-0"
                  />
                </div>
              </div>

              <!--Password-->
              <div class="form-group" v-if="!user._id">
                <label>Password</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-key"></i>
                    </div>
                  </div>
                  <input
                    v-model="user.password"
                    :type="showPassword ? 'text' : 'password'"
                    :label="$t('input.password')"
                    :rules="rules.password"
                    @click:append="showPassword = !showPassword"
                    class="form-control"
                  />
                </div>
              </div>

              <!--currency type-->
              <div
                class="form-group"
                v-if="
                  (userp && userp.role_id == '611532b19c6e77ad0fd50c1c') ||
                  userp.role_id == '612a3fcc1a1fcead8871e822'
                "
              ></div>
              <div class="form-group" v-else>
                <v-select
                  :disabled="true"
                  v-model="userp.currency_type"
                  :items="currencyType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.chose_type')"
                ></v-select>
              </div>

              <!--bank acc-->
              <div class="form-group">
                <label>Account Note</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <i class="fas fa-money-bill-alt"></i>
                    </div>
                  </div>
                  <input v-model="user.account_info" class="form-control" />
                </div>
              </div>

              <!--User Role-->
              <div class="form-group">
                <label>User Role</label>
                <div>
                  <v-select
                    v-model="user.role_id"
                    :items="optionRoles"
                    item-text="child_role_name"
                    item-value="child_role_id"
                    :rules="rules.role_id"
                    @input="_alert(user.role_id)"
                    :disabled="
                      (userp.role_id != '611532b19c6e77ad0fd50c1c' ||
                        userp.role_id != '612a3fcc1a1fcead8871e822') &&
                      user._id
                        ? true
                        : false
                    "
                  />
                </div>
              </div>
              <div
                class="form-group"
                v-if="user && user.role_id == '6191dce94825e65937fa2340' || user && user.role_id == '6191dc644825e6f57ffa231f'"
              >
                <v-col cols="12" sm="12" md="12" style="padding:0px;">
                  <v-checkbox v-model="user.full_permission" label="Full Permission"></v-checkbox>
                </v-col>
              </div>

              <!--app_id-->
              <div
                class="form-group"
                v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'"
              >
                <v-select
                  v-model="user.app_id"
                  :items="listApp"
                  :rules="
                    user.role_id == '612a3fcc1a1fcead8871e822'
                      ? rules.app_id
                      : ''
                  "
                  :disabled="
                        user._id
                        ? true
                        : false
                    "
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.app_name')"
                />
              </div>


              <!-- limit betting for player -->
              <div
                v-if="
                  !user._id &&
                  (userp.role_id == '61829dc7e64b1b17688326d5' ||
                    userp.role_id == '61829dd1e64b1b17688326df' ||
                    userp.role_id == '61829de6e64b1b17688326f3' ||
                    userp.role_id == '6424071f50b77c6cf890ab0d' ||
                    userp.role_id == '6472bf9b1ba4b8a1604940c1' 
                  )
                "
                class="pt-5 mt-5"
              >
                <div class="form-group" hidden>
                  <label>
                    Maximum win a day limit
                    <span style="color: blue; font-size: larger">
                      {{ currencyFormatKh(limitConfig[0].total_bet_win_a_day) }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      v-bind:options="options"
                      v-model="dataConfig.total_bet_win_a_day"
                      :rules="[rules.conf_limit_win_a_day]"
                      type="number"
                    />
                  </div>
                </div>

                <div class="form-group" hidden>
                  <label>
                    Total Bet for 1 Fight
                    <span style="color: blue; font-size: larger">
                      {{ currencyFormatKh(limitConfig[0].total_bet_a_fight) }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      v-bind:options="options"
                      v-model="dataConfig.total_bet_a_fight"
                      :rules="[rules.conf_limit_in_a_fight]"
                      type="number"
                    />
                  </div>
                </div>

                <div class="form-group" hidden>
                  <label>
                    Maximum bet per 1 invoice
                    <span style="color: blue; font-size: larger">
                      {{ currencyFormatKh(limitConfig[0].amount_max) }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      class="mt-5"
                      v-bind:options="options"
                      v-model="dataConfig.amount_max"
                      :rules="[rules.conf_amount_max]"
                      type="number"
                    />
                  </div>
                </div>

                <div class="form-group" hidden>
                  <label>
                    Minimum bet per 1 invoice
                    <span style="color: blue; font-size: larger">
                      {{
                        userp.currency_type == 1
                          ? 1
                          : userp.currency_type == 2
                          ? currencyFormatKh(limitConfig[0].amount_min)
                          : userp.currency_type == 4
                          ? currencyFormatKh(limitConfig[0].amount_min)
                          : "loading.."
                      }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      class="mt-5"
                      v-bind:options="options"
                      v-model="dataConfig.amount_min"
                      :rules="[rules.conf_amount_min]"
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <!--listAllCompany-->
              <v-row v-if="user && user.role_id == '612a40a51a1fcead8871e833'">
                <v-combobox
                  v-model="user.company_ids"
                  :items="listCompany"
                  :label="$t('input.listAllCompany')"
                  multiple
                  chips
                  item-text="user_name"
                  item-value="_id"
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item._id)"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      class="v-chip--select-multi"
                      @input="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text mr-1"
                        v-text="data.item.user_name.slice(0, 1).toUpperCase()"
                      />
                      {{ data.item.user_name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-row>

              <!-- Select Senior -->
              <v-row v-if="user.role_id == '62c5a1a9f930dbb6007069b7'">
                <v-combobox
                  v-model="user.company_ids"
                  :items="listSenior"
                  :label="$t('input.listAllSenior')"
                  multiple
                  chips
                  item-text="user_name"
                  item-value="_id"
                  persistent-hint
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item._id)"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      class="v-chip--select-multi"
                      @input="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text mr-1"
                        v-text="data.item.user_name.slice(0, 1).toUpperCase()"
                      />
                      {{ data.item.user_name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-row>

              <!--select Cock Channel Type -->
              <v-row v-if="user && user.role_id == '612a40c71a1fcead8871e835'">
                <v-select
                  v-model="user.channel_post"
                  :items="cockChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Klaklouk Channel Type -->
              <v-row v-if="user && user.role_id == '63cddbd5b51c1415b8bae1df'">
                <v-select
                  v-model="user.channel_post"
                  :items="klakloukChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Baccarat Channel Type -->
              <v-row v-if="user && user.role_id == '63cddc81b51c1415b8bae1fd'">
                <v-select
                  v-model="user.channel_post"
                  :items="baccaratChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select New Baccarat Channel Type 18 -->
              <v-row v-if="user && user.role_id == '64643d791ba4b87724c7158b'">
                <v-select
                  v-model="user.channel_post"
                  :items="baccaratChannelType18"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Dragon Channel Type -->
              <v-row v-if="user && user.role_id == '63cddccfb51c1415b8bae208'">
                <v-select
                  v-model="user.channel_post"
                  :items="dragonChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select new Dragon Channel Type 19 -->
              <v-row v-if="user && user.role_id == '64643d1f1ba4b87724c7158a'">
                <v-select
                  v-model="user.channel_post"
                  :items="dragonChannelType19"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Lotto Channel Type -->
              <v-row v-if="user && user.role_id == '63cddd30b51c1415b8bae213'">
                <v-select
                  v-model="user.channel_post"
                  :items="lottoChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Yuki Channel Type -->
              <v-row v-if="user && user.role_id == '63cddd6fb51c1415b8bae21e'">
                <v-select
                  v-model="user.channel_post"
                  :items="yukiChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Apong Channel Type -->
              <v-row v-if="user && user.role_id == '63cddde1b51c1415b8bae229'">
                <v-select
                  v-model="user.channel_post"
                  :items="apongChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Fantan Channel Type -->
              <v-row v-if="user && user.role_id == '6406b857c2fa980eb0174708'">
                <v-select
                  v-model="user.channel_post"
                  :items="fantanChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!--select Fantan Channel Type -->
              <v-row v-if="user && user.role_id == '64095989c2fa980eb0174893'">
                <v-select
                  v-model="user.channel_post"
                  :items="taisievChannelType"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>
              <!--select Han3 Channel -->
              <v-row v-if="user && user.role_id == '648fd726fc595e5604b69ded'">
                <v-select
                  v-model="user.channel_post"
                  :items="han3ChannelType21"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>
              <!--select Han3 Channel -->
              <v-row v-if="user && user.role_id == '648fd746fc595e5604b69dee'">
                <v-select
                  v-model="user.channel_post"
                  :items="han2ChannelType20"
                  item-text="name"
                  item-value="_id"
                  :label="$t('input.channel_type')"
                />
              </v-row>

              <!-- Hight Lever -->
              <div
                v-if="
                  !user._id &&
                  userp.role_id !== '61829dc7e64b1b17688326d5' &&
                  userp.role_id !== '61829dd1e64b1b17688326df' &&
                  userp.role_id !== '61829de6e64b1b17688326f3' &&
                  userp.role_id !== '6424071f50b77c6cf890ab0d' &&
                  userp.role_id !== '6472bf9b1ba4b8a1604940c1' &&
                  userp.role_id !== '611532b19c6e77ad0fd50c1c'
                "
                class="pt-5 mt-5"
              >
                <div class="form-group" hidden>
                  <label>
                    Maximum win a day limit
                    <span style="color: blue; font-size: larger">
                      {{ currencyFormatKh(limitConfig[0].total_bet_win_a_day) }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      v-bind:options="options"
                      v-model="dataConfig.total_bet_win_a_day"
                      :rules="[rules.conf_limit_win_a_day]"
                      type="number"
                    />
                  </div>
                </div>

                <div class="form-group" hidden>
                  <label>
                    Total Bet for 1 Fight
                    <span style="color: blue; font-size: larger">
                      {{ currencyFormatKh(limitConfig[0].total_bet_a_fight) }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      v-bind:options="options"
                      v-model="dataConfig.total_bet_a_fight"
                      :rules="[rules.conf_limit_in_a_fight]"
                      type="number"
                    />
                  </div>
                </div>

                <div class="form-group" hidden>
                  <label>
                    Maximum bet per 1 invoice
                    <span style="color: blue; font-size: larger">
                      {{ currencyFormatKh(limitConfig[0].amount_max) }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      class="mt-5"
                      v-bind:options="options"
                      v-model="dataConfig.amount_max"
                      :rules="[rules.conf_amount_max]"
                      type="number"
                    />
                  </div>
                </div>

                <div class="form-group" hidden>
                  <label>
                    Minimum bet per 1 invoice
                    <span style="color: blue; font-size: larger">
                      {{
                        userp.currency_type == 1
                          ? 1
                          : userp.currency_type == 2
                          ? currencyFormatKh(limitConfig[0].amount_min)
                          : userp.currency_type == 4
                          ? currencyFormatKh(limitConfig[0].amount_min)
                          : "loading.."
                      }}
                    </span>
                  </label>
                  <div>
                    <vuetify-money
                      class="mt-5"
                      v-bind:options="options"
                      v-model="dataConfig.amount_min"
                      :rules="[rules.conf_amount_min]"
                      type="number"
                    />
                  </div>
                </div>
              </div>

              <!-- Select operaion in developer -->
              <div v-if="user && (user.role_id == '612a3fcc1a1fcead8871e822' ||
                  user.role_id == '61829d84e64b1b17688326a3' ||
                  user.role_id == '61829d75e64b1b1768832699' ||
                  user.role_id == '61829da2e64b1b17688326b7' ||
                  user.role_id == '61829dbbe64b1b17688326cb' ||
                  user.role_id == '61829dc7e64b1b17688326d5' ||
                  user.role_id == '61829dd1e64b1b17688326df' ||
                  user.role_id == '61829df5e64b1b1768832700' ||
                  user.role_id == '61829dfee64b1b1768832709' ||
                  user.role_id == '6424067a50b77c6cf890ab0b' ||
                  user.role_id == '642406db50b77c6cf890ab0c' ||
                  user.role_id == '6424071f50b77c6cf890ab0d' ||
                  user.role_id == '6424075250b77c6cf890ab0e' ||
                  user.role_id == '6472b82f1ba4b8a1604940a9' ||
                  user.role_id == '6472bb0d1ba4b8a1604940b1' ||
                  user.role_id == '6472bf9b1ba4b8a1604940c1' ||
                  user.role_id == '6472bffd1ba4b8a1604940c3' 
                  )">
                <span class="text"> Select Operation</span>
                <v-container fluid>
                  <v-row>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel1" v-model="selectedCh.channel_1" :label="$t('input.channel1')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/cockfight-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel1") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                      
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel2" v-model="selectedCh.channel_2" :label="$t('input.channel2')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/cockfight-icon-2.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel2") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel3" v-model="selectedCh.channel_3" :label="$t('input.channel3')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/cockfight-icon-3.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel3") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel4" v-model="selectedCh.channel_4" :label="$t('input.channel4')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/cockfight-icon-4.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel4") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel15" v-model="selectedCh.channel_15" :label="$t('input.channel15')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/cockfight-icon-5.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel15") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel16" v-model="selectedCh.channel_16" :label="$t('input.channel16')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/cockfight-icon-6.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel16") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel5" v-model="selectedCh.channel_5" :label="$t('input.channel5')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/klaklouk-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel5") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel6" v-model="selectedCh.channel_6" :label="$t('input.channel6')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/baccarat-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel6") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel7" v-model="selectedCh.channel_7" :label="$t('input.channel7')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/baccarat-icon-2.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel7") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel18" v-model="selectedCh.channel_18" :label="$t('input.channel18')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/baccarat_icon_3.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel18") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel8" v-model="selectedCh.channel_8" :label="$t('input.channel8')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/dragon-tiger-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel8") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel9" v-model="selectedCh.channel_9" :label="$t('input.channel9')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/dragon-tiger-icon-2.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel9") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel19" v-model="selectedCh.channel_19" :label="$t('input.channel19')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/dragon_tiger_icon_3.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel19") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel10" v-model="selectedCh.channel_10" :label="$t('input.channel10')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/lotto-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel10") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel11" v-model="selectedCh.channel_11" :label="$t('input.channel11')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/yuki-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel11") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel12" v-model="selectedCh.channel_12" :label="$t('input.channel12')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/apong-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel12") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel17" v-model="selectedCh.channel_17" :label="$t('input.channel17')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/apong-icon-2.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel17") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel13" v-model="selectedCh.channel_13" :label="$t('input.channel13')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/fantan-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel13") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel14" v-model="selectedCh.channel_14" :label="$t('input.channel14')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/taisiev-icon-1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel14") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel20" v-model="selectedCh.channel_20" :label="$t('input.channel20')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/han2_1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel20") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>

                    <v-col
                      cols="2"
                      sm="2"
                      md="2"
                      style="padding:0px;"
                    >
                    <v-checkbox v-if="showChannel21" v-model="selectedCh.channel_21" :label="$t('input.channel21')">
                      <template v-slot:label>
                        <v-avatar tile size="20">
                          <img
                            src="/images/han3_1.png"
                          > 
                        </v-avatar>
                        <v-row style="margin-left: 0px;margin-bottom: 0px;margin-top:0px;">
                          <v-col cols="12" md="12" style="padding: 2px !important;">
                            <span>{{ $t("input.channel21") }}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-checkbox>
                    </v-col>
                  </v-row>
                  
                </v-container>
              </div>
            </v-form>
          </div>
        </div>
        <div class="card-footer text-right">
          <v-btn :loading="loading" class="btn btn-primary" :disabled="loading" @click="validate" color="primary" style="background-color: #6975E2;">
            {{ !user._id ? "Create" : "Update" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    selectedChannel: {
      type: Array,
      default: () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15,16,17,18,19,20,21];
      },
    },
    userp: {
      type: Object,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    listCompany: {
      type: Array,
      default: null,
    },
    listSenior: {
      type: Array,
      default: null,
    },
    listApp: {
      type: Array,
      default: null,
    },
    darkmode: {
      type: Boolean,
    },
    optionRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
    },
    rules: {
      type: Object,
      default: function () {
        return {
          user_name: [
            (v) => !!v || "Name is required",
            (v) => {
              const pattern = /^([a-zA-Z0-9]*)$/;
              return pattern.test(v) || "Invalid Username.";
            },
          ],
          account_info: [(v) => !!v || "description is required"],
          balance: [(v) => !!v || "Order is required"],
          password: [(v) => !!v || "Password is required"],
          enable: [(v) => !!v || "Enable is required"],
          role_id: [(v) => !!v || "role is required"],
          app_id: [(v) => !!v || "App Name is required"],
          selectedchannel1: [(v) => !!v || "channel is required"],
          selectedchannel2: [(v) => !!v || "channel is required"],
          selectedchannel3: [(v) => !!v || "channel is required"],
          selectedchannel4: [(v) => !!v || "channel is required"],
          selectedchannel5: [(v) => !!v || "channel is required"],
          selectedchannel6: [(v) => !!v || "channel is required"],
          selectedchannel7: [(v) => !!v || "channel is required"],
          selectedchannel8: [(v) => !!v || "channel is required"],
          selectedchannel9: [(v) => !!v || "channel is required"],
          selectedchannel10: [(v) => !!v || "channel is required"],
          selectedchannel11: [(v) => !!v || "channel is required"],
          selectedchannel12: [(v) => !!v || "channel is required"],
          selectedchannel13: [(v) => !!v || "channel is required"],
          selectedchannel14: [(v) => !!v || "channel is required"],
          selectedchannel15: [(v) => !!v || "channel is required"],
          selectedchannel16: [(v) => !!v || "channel is required"],
          selectedchannel17: [(v) => !!v || "channel is required"],
          selectedchannel18: [(v) => !!v || "channel is required"],
          selectedchannel19: [(v) => !!v || "channel is required"],
          selectedchannel20: [(v) => !!v || "channel is required"],
          selectedchannel21: [(v) => !!v || "channel is required"],
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: function () {
        return {
          _id: null,
          password: 123456,
          enable: true,
          app_id: null,
          role: null,
          full_permission: false,
          // balance: "",
        };
      },
    },
  },
  computed: {
    showChannel1() {
      
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 1) {
          return true;
        }
      }
      return false;
    },
    showChannel2() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 2) {
          return true;
        }
      }
      return false;
    },
    showChannel3() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 3) {
          return true;
        }
      }
      return false;
    },
    showChannel4() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 4) {
          return true;
        }
      }
      return false;
    },
    showChannel5() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 5) {
          return true;
        }
      }
      return false;
    },
    showChannel6() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 6) {
          return true;
        }
      }
      return false;
    },
    showChannel7() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 7) {
          return true;
        }
      }
      return false;
    },
    showChannel8() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 8) {
          return true;
        }
      }
      return false;
    },
    showChannel9() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 9) {
          return true;
        }
      }
      return false;
    },
    showChannel10() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 10) {
          return true;
        }
      }
      return false;
    },
    showChannel11() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 11) {
          return true;
        }
      }
      return false;
    },
    showChannel12() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 12) {
          return true;
        }
      }
      return false;
    },
    showChannel13() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 13) {
          return true;
        }
      }
      return false;
    },
    showChannel14() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 14) {
          return true;
        }
      }
      return false;
    },
    showChannel15() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 15) {
          return true;
        }
      }
      return false;
    },
    showChannel16() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 16) {
          return true;
        }
      }
      return false;
    },
    showChannel17() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 17) {
          return true;
        }
      }
      return false;
    },
    showChannel18() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 18) {
          return true;
        }
      }
      return false;
    },
    showChannel19() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 19) {
          return true;
        }
      }
      return false;
    },
    showChannel20() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 20) {
          return true;
        }
      }
      return false;
    },
    showChannel21() {
      for (let j in this.limitConfig) {
        if (this.limitConfig[j].channel_type == 21) {
          return true;
        }
      }
      return false;
    },
    currencyType() {
      if (this.userp && this.userp.currency_type == 1)
        return [{ name: "លុយដុល្លា Usd", _id: 1 }];
      if (this.userp && this.userp.currency_type == 2)
        return [{ name: "លុយខ្មែរ Riel ", _id: 2 }];
      if (this.userp && this.userp.currency_type == 4)
        return [{ name: "លុយវៀតណាម Dong ", _id: 4 }];
      if (this.userp && this.userp.currency_type == 5)
        return [{ name: "លុយឥណ្ឌូនេស៊ី Rupiahs ", _id: 5 }];
      else
        return [
          { _id: 1, name: "លុយដុល្លា - Usd" },
          { _id: 2, name: "លុយរៀល - Khmer" },
          { _id: 4, name: "លុយវៀតណាម - Dong" },
          { _id: 5, name: "លុយឥណ្ឌូនេស៊ី - Rupiahs" },
        ];
    },
    currencyTypeDev() {
      return [
        { currency_type: 1, name: "លុយដុល្លា - Usd" },
        { currency_type: 2, name: "លុយរៀល - Khmer" },
        { currency_type: 4, name: "លុយវៀតណាម - Dong" },
        { currency_type: 5, name: "លុយឥណ្ឌូនេស៊ី - Rupiahs" },
      ];
    },
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
  },
  data: () => {
    return {
      dataConfig: {
        // total_bet_win_a_day:2000,
        // total_bet_a_fight: 100,
        // amount_max:100,
        // amount_min:1
      },
      selectionChannel1: [],
      selectedChannel1: [],
      selectionChannel2: [],
      selectedChannel2: [],
      selectionChannel3: [],
      selectedChannel3: [],
      selectionChannel4: [],
      selectedChannel4: [],
      selectionChannel5: [],
      selectedChannel5: [],
      selectionChannel6: [],
      selectedChannel6: [],
      selectionChannel7: [],
      selectedChannel7: [],
      selectionChannel8: [],
      selectedChannel8: [],
      selectionChannel9: [],
      selectedChannel9: [],
      selectionChannel10: [],
      selectedChannel10: [],
      selectionChannel11: [],
      selectedChannel11: [],
      selectionChannel12: [],
      selectedChannel12: [],
      selectionChannel13: [],
      selectedChannel13: [],
      selectionChannel14: [],
      selectedChannel14: [],
      selectionChannel15: [],
      selectedChannel15: [],
      selectionChannel16: [],
      selectedChannel16: [],
      selectionChannel17: [],
      selectedChannel17: [],
      selectionChannel18: [],
      selectedChannel18: [],
      selectionChannel19: [],
      selectedChannel19: [],
      selectionChannel20: [],
      selectedChannel20: [],
      selectionChannel21: [],
      selectedChannel21: [],
      showPassword: false,
      options: {
        locale: "integer",
        length: 21,
        precision: 0,
      },
      limitConfig: [],
      items: [
        { _id: 1, name: "លុយដុល្លា - Usd" },
        { _id: 2, name: "លុយរៀល - Khmer" },
        { _id: 4, name: "លុយវៀតណាម - Dong" },
        { _id: 5, name: "លុយឥណ្ឌូនេស៊ី - Rupiahs" },
      ],
      cockChannelType: [
        { _id: 1, name: "CO1" },
        { _id: 2, name: "CO2" },
        { _id: 3, name: "CO3" },
        { _id: 4, name: "CO4" },
        { _id: 15, name: "CO15" },
        { _id: 16, name: "CO16" },
      ],
      klakloukChannelType: [{ _id: 5, name: "KL1" }],
      baccaratChannelType: [
        { _id: 6, name: "BA1" },
        { _id: 7, name: "BA2" },
        { _id: 18, name: "BA3" },
      ],
      baccaratChannelType18: [{ _id: 18, name: "BA3" }],
      dragonChannelType: [
        { _id: 8, name: "TD1" },
        { _id: 9, name: "TD2" },
        { _id: 19, name: "TD3" },
      ],
      dragonChannelType19: [{ _id: 19, name: "TD3" }],
      lottoChannelType: [{ _id: 10, name: "LO1" }],
      yukiChannelType: [{ _id: 11, name: "YU1" }],
      apongChannelType: [
        { _id: 12, name: "AP1" },
        { _id: 17, name: "AP17" }
      ],
      fantanChannelType: [{ _id: 13, name: "FT1" }],
      taisievChannelType: [{ _id: 14, name: "TS1" }],
      han2ChannelType20: [{ _id: 20, name: "Han2" }],
      han3ChannelType21: [{ _id: 21, name: "Han3" }],
      selectedCh: [],
      vipChanel1: [
        {
          id: 11,
          vip_option: 1,
          channel_type: 1,
        },
        {
          id: 12,
          vip_option: 2,
          channel_type: 1,
        },
        {
          id: 13,
          vip_option: 3,
          channel_type: 1,
        },
      ],
      vipChanel2: [
        {
          id: 21,
          vip_option: 1,
          channel_type: 2,
        },
        {
          id: 22,
          vip_option: 2,
          channel_type: 2,
        },
        {
          id: 23,
          vip_option: 3,
          channel_type: 2,
        },
      ],

      vipChanel3: [
        {
          id: 31,
          vip_option: 1,
          channel_type: 3,
        },
        {
          id: 32,
          vip_option: 2,
          channel_type: 3,
        },
        {
          id: 33,
          vip_option: 3,
          channel_type: 3,
        },
      ],
      vipChanel4: [
        {
          id: 41,
          vip_option: 1,
          channel_type: 4,
        },
        {
          id: 42,
          vip_option: 2,
          channel_type: 4,
        },
        {
          id: 43,
          vip_option: 3,
          channel_type: 4,
        },
      ],
      vipChanel5: [
        {
          id: 51,
          vip_option: 1,
          channel_type: 5,
        },
        {
          id: 52,
          vip_option: 2,
          channel_type: 5,
        },
        {
          id: 53,
          vip_option: 3,
          channel_type: 5,
        },
      ],
      vipChanel6: [
        {
          id: 61,
          vip_option: 1,
          channel_type: 6,
        },
        {
          id: 62,
          vip_option: 2,
          channel_type: 6,
        },
        {
          id: 63,
          vip_option: 3,
          channel_type: 6,
        },
      ],
      vipChanel7: [
        {
          id: 71,
          vip_option: 1,
          channel_type: 7,
        },
        {
          id: 72,
          vip_option: 2,
          channel_type: 7,
        },
        {
          id: 73,
          vip_option: 3,
          channel_type: 7,
        },
      ],
      vipChanel8: [
        {
          id: 81,
          vip_option: 1,
          channel_type: 8,
        },
        {
          id: 82,
          vip_option: 2,
          channel_type: 8,
        },
        {
          id: 83,
          vip_option: 3,
          channel_type: 8,
        },
      ],
      vipChanel9: [
        {
          id: 91,
          vip_option: 1,
          channel_type: 9,
        },
        {
          id: 92,
          vip_option: 2,
          channel_type: 9,
        },
        {
          id: 93,
          vip_option: 3,
          channel_type: 9,
        },
      ],
      vipChanel10: [
        {
          id: 101,
          vip_option: 1,
          channel_type: 10,
        },
        {
          id: 102,
          vip_option: 2,
          channel_type: 10,
        },
        {
          id: 103,
          vip_option: 3,
          channel_type: 10,
        },
      ],
      vipChanel11: [
        {
          id: 111,
          vip_option: 1,
          channel_type: 11,
        },
        {
          id: 112,
          vip_option: 2,
          channel_type: 11,
        },
        {
          id: 113,
          vip_option: 3,
          channel_type: 11,
        },
      ],
      vipChanel12: [
        {
          id: 121,
          vip_option: 1,
          channel_type: 12,
        },
        {
          id: 122,
          vip_option: 2,
          channel_type: 12,
        },
        {
          id: 123,
          vip_option: 3,
          channel_type: 12,
        },
      ],
      vipChanel13: [
        {
          id: 131,
          vip_option: 1,
          channel_type: 13,
        },
        {
          id: 132,
          vip_option: 2,
          channel_type: 13,
        },
        {
          id: 133,
          vip_option: 3,
          channel_type: 13,
        },
      ],
      vipChanel14: [
        {
          id: 141,
          vip_option: 1,
          channel_type: 14,
        },
        {
          id: 142,
          vip_option: 2,
          channel_type: 14,
        },
        {
          id: 143,
          vip_option: 3,
          channel_type: 14,
        },
      ],
      vipChanel15: [
        {
          id: 151,
          vip_option: 1,
          channel_type: 15,
        },
        {
          id: 152,
          vip_option: 2,
          channel_type: 15,
        },
        {
          id: 153,
          vip_option: 3,
          channel_type: 15,
        },
      ],
      vipChanel16: [
        {
          id: 161,
          vip_option: 1,
          channel_type: 16,
        },
        {
          id: 162,
          vip_option: 2,
          channel_type: 16,
        },
        {
          id: 163,
          vip_option: 3,
          channel_type: 16,
        },
      ],
      vipChanel17: [
        {
          id: 171,
          vip_option: 1,
          channel_type: 17,
        },
        {
          id: 172,
          vip_option: 2,
          channel_type: 17,
        },
        {
          id: 173,
          vip_option: 3,
          channel_type: 17,
        },
      ],
      vipChanel20: [
        {
          id: 201,
          vip_option: 1,
          channel_type: 20,
        },
        {
          id: 202,
          vip_option: 2,
          channel_type: 20,
        },
        {
          id: 203,
          vip_option: 3,
          channel_type: 20,
        },
      ],
      vipChanel21: [
        {
          id: 211,
          vip_option: 1,
          channel_type: 21
        },
        {
          id: 212,
          vip_option: 2,
          channel_type: 21,
        },
        {
          id: 213,
          vip_option: 3,
          channel_type: 21,
        },
      ],

      selectedChAgent: [],
      vipChanelAgent1: [
        {
          id: 11,
          vip_option: 1,
          channel_type: 1,
        },
      ],
      vipChanelAgent2: [
        {
          id: 21,
          vip_option: 1,
          channel_type: 2,
        },
      ],

      vipChanelAgent3: [
        {
          id: 31,
          vip_option: 1,
          channel_type: 3,
        },
      ],
      vipChanelAgent4: [
        {
          id: 41,
          vip_option: 1,
          channel_type: 4,
        },
      ],
      vipChanelAgent5: [
        {
          id: 51,
          vip_option: 1,
          channel_type: 5,
        },
      ],
      vipChanelAgent6: [
        {
          id: 61,
          vip_option: 1,
          channel_type: 6,
        },
      ],
      vipChanelAgent7: [
        {
          id: 71,
          vip_option: 1,
          channel_type: 7,
        },
      ],
      vipChanelAgent8: [
        {
          id: 81,
          vip_option: 1,
          channel_type: 8,
        },
      ],
      vipChanelAgent9: [
        {
          id: 91,
          vip_option: 1,
          channel_type: 9,
        },
      ],
      vipChanelAgent10: [
        {
          id: 101,
          vip_option: 1,
          channel_type: 10,
        },
      ],
      vipChanelAgent11: [
        {
          id: 111,
          vip_option: 1,
          channel_type: 11,
        },
      ],
      vipChanelAgent12: [
        {
          id: 121,
          vip_option: 1,
          channel_type: 12,
        },
      ],
      vipChanelAgent13: [
        {
          id: 131,
          vip_option: 1,
          channel_type: 13,
        },
      ],
      vipChanelAgent14: [
        {
          id: 141,
          vip_option: 1,
          channel_type: 14,
        },
      ],
      vipChanelAgent15: [
        {
          id: 151,
          vip_option: 1,
          channel_type: 15,
        },
      ],
      vipChanelAgent16: [
        {
          id: 161,
          vip_option: 1,
          channel_type: 16,
        },
      ],
      vipChanelAgent17: [
        {
          id: 171,
          vip_option: 1,
          channel_type: 17,
        },
      ],
      vipChanelAgent20: [
        {
          id: 201,
          vip_option: 1,
          channel_type: 20,
        },
      ],
    };
  },
  watch: {
    
    dialog: async function (newV) {
      console.log("newV",newV);
      await this.getLimitData();
      await this.correctSelectChanel(this.limitConfig);
      this.$forceUpdate();
      if (newV == false) {
        this.clearOldData();
      }
    },
    optionRoles: function () {},
    listCompany: function () {},
    listApp: function () {},
    user: function (newV) {
      this.$forceUpdate();
      if (newV && newV.role_id == "612a40a51a1fcead8871e833")
        this.$emit("handleListCompany");
      if (this.user && !this.user._id && !this.user.role_id) {
        //  Level developer
        this.userp.role_id == "611532b19c6e77ad0fd50c1c"
          ? (this.user.role_id = "612a3fcc1a1fcead8871e822")
          : "";
        //  Level Company
        this.userp.role_id == "612a3fcc1a1fcead8871e822"
          ? (this.user.role_id = "61829d84e64b1b17688326a3")
          : "";
        //  Level Senior Dollar
        this.userp.role_id == "61829d84e64b1b17688326a3"
          ? (this.user.role_id = "61829dbbe64b1b17688326cb")
          : "";
        //  Level Master Dollar
        this.userp.role_id == "61829dbbe64b1b17688326cb"
          ? (this.user.role_id = "61829dd1e64b1b17688326df")
          : "";
        //  Level Agent Dollar
        this.userp.role_id == "61829dd1e64b1b17688326df"
          ? (this.user.role_id = "61829dfee64b1b1768832709")
          : "";
        //  Level Senior Riel
        this.userp.role_id == "61829d75e64b1b1768832699"
          ? (this.user.role_id = "61829da2e64b1b17688326b7")
          : "";
        //  Level Master Riel
        this.userp.role_id == "61829da2e64b1b17688326b7"
          ? (this.user.role_id = "61829dc7e64b1b17688326d5")
          : "";
        //  Level Agent Riel
        this.userp.role_id == "61829dc7e64b1b17688326d5"
          ? (this.user.role_id = "61829df5e64b1b1768832700")
          : "";
        //  Level Senior Baht
        this.userp.role_id == "61829d93e64b1b17688326ad"
          ? (this.user.role_id = "61829db0e64b1b17688326c1")
          : "";
        //  Level Master Baht
        this.userp.role_id == "61829db0e64b1b17688326c1"
          ? (this.user.role_id = "61829de6e64b1b17688326f3")
          : "";
        //  Level Agent Baht
        this.userp.role_id == "61829de6e64b1b17688326f3"
          ? (this.user.role_id = "61829e05e64b1b1768832712")
          : "";
          //  Level Senior VN
        this.userp.role_id == "6424067a50b77c6cf890ab0b"
          ? (this.user.role_id = "642406db50b77c6cf890ab0c")
          : "";
        //  Level Master VN
        this.userp.role_id == "642406db50b77c6cf890ab0c"
          ? (this.user.role_id = "6424071f50b77c6cf890ab0d")
          : "";
        //  Level Agent VN
        this.userp.role_id == "6424071f50b77c6cf890ab0d"
          ? (this.user.role_id = "6424075250b77c6cf890ab0e")
          : "";

        //  Level Senior RP
        this.userp.role_id == "6472b82f1ba4b8a1604940a9"
          ? (this.user.role_id = "6472bb0d1ba4b8a1604940b1")
          : "";
        //  Level Master RP
        this.userp.role_id == "6472bb0d1ba4b8a1604940b1"
          ? (this.user.role_id = "6472bf9b1ba4b8a1604940c1")
          : "";
        //  Level Agent RP
        this.userp.role_id == "6472bf9b1ba4b8a1604940c1"
          ? (this.user.role_id = "6472bffd1ba4b8a1604940c3")
          : "";
      }
    },
  },
  created() {
    if (this.user && !this.user._id) {
      //  Level developer
      this.userp && this.userp.role_id == "611532b19c6e77ad0fd50c1c"
        ? (this.user.role_id = "612a3fcc1a1fcead8871e822")
        : "";
      //  Level Company
      this.userp && this.userp.role_id == "612a3fcc1a1fcead8871e822"
        ? (this.user.role_id = "61829d84e64b1b17688326a3")
        : "";
      //  Level Senior Dollar
      this.userp && this.userp.role_id == "61829d84e64b1b17688326a3"
        ? (this.user.role_id = "61829dbbe64b1b17688326cb")
        : "";
      //  Level Master Dollar
      this.userp && this.userp.role_id == "61829dbbe64b1b17688326cb"
        ? (this.user.role_id = "61829dd1e64b1b17688326df")
        : "";
      //  Level Agent Dollar
      this.userp && this.userp.role_id == "61829dd1e64b1b17688326df"
        ? (this.user.role_id = "61829dfee64b1b1768832709")
        : "";
      //  Level Senior Riel
      this.userp && this.userp.role_id == "61829d75e64b1b1768832699"
        ? (this.user.role_id = "61829da2e64b1b17688326b7")
        : "";
      //  Level Master Riel
      this.userp && this.userp.role_id == "61829da2e64b1b17688326b7"
        ? (this.user.role_id = "61829dc7e64b1b17688326d5")
        : "";
      //  Level Agent Riel
      this.userp && this.userp.role_id == "61829dc7e64b1b17688326d5"
        ? (this.user.role_id = "61829df5e64b1b1768832700")
        : "";
      //  Level Senior Baht
      this.userp && this.userp.role_id == "61829d93e64b1b17688326ad"
        ? (this.user.role_id = "61829db0e64b1b17688326c1")
        : "";
      //  Level Master Baht
      this.userp && this.userp.role_id == "61829db0e64b1b17688326c1"
        ? (this.user.role_id = "61829de6e64b1b17688326f3")
        : "";
      //  Level Agent Baht
      this.userp && this.userp.role_id == "61829de6e64b1b17688326f3"
        ? (this.user.role_id = "61829e05e64b1b1768832712")
        : "";
      //  Level Senior VN
      this.userp && this.userp.role_id == "6424067a50b77c6cf890ab0b"
        ? (this.user.role_id = "642406db50b77c6cf890ab0c")
        : "";
      //  Level Master VN
      this.userp && this.userp.role_id == "642406db50b77c6cf890ab0c"
        ? (this.user.role_id = "6424071f50b77c6cf890ab0d")
        : "";
      //  Level Agent VN
      this.userp && this.userp.role_id == "6424071f50b77c6cf890ab0d"
        ? (this.user.role_id = "6424075250b77c6cf890ab0e")
        : "";

      //  Level Senior RP
      this.userp && this.userp.role_id == "6472b82f1ba4b8a1604940a9"
        ? (this.user.role_id = "6472bb0d1ba4b8a1604940b1")
        : "";
      //  Level Master RP
      this.userp && this.userp.role_id == "6472bb0d1ba4b8a1604940b1"
        ? (this.user.role_id = "6472bf9b1ba4b8a1604940c1")
        : "";
      //  Level Agent RP
      this.userp && this.userp.role_id == "6472bf9b1ba4b8a1604940c1"
        ? (this.user.role_id = "6472bffd1ba4b8a1604940c3")
        : "";
    }
  },

  methods: {
    _alert(role_id) {
      if (role_id == "61829d84e64b1b17688326a3") {
        this.userp.currency_type = 1;
        this.getLimitData();
      }
      if (role_id == "61829d75e64b1b1768832699") {
        this.userp.currency_type = 2;
        this.getLimitData();
      }
      if (role_id == "6424067a50b77c6cf890ab0b") {
        this.userp.currency_type = 4;
        this.getLimitData();
      }
      if (role_id == "6472b82f1ba4b8a1604940a9") {
        this.userp.currency_type = 5;
        this.getLimitData();
      }
      if (role_id == "612a40a51a1fcead8871e833") {
        this.user.role_id = "612a40a51a1fcead8871e833";
      }
      if (role_id == "612a3fcc1a1fcead8871e822") {
        this.user.role_id = "612a3fcc1a1fcead8871e822";
      } else {
        this.user.app_id = null;
      }
    },
    validate() {
     
      if (this.$refs.form.validate()) {
      
        this.user.dataConfig = this.dataConfig;
        this.userp.role_id != "611532b19c6e77ad0fd50c1c" &&
        this.userp.role_id != "612a3fcc1a1fcead8871e822"
          ? (this.user.currency_type = this.userp.currency_type)
          : "";
        if (
          this.userp.role_id !== "61829dc7e64b1b17688326d5" ||
          this.userp.role_id !== "61829dd1e64b1b17688326df" ||
          this.userp.role_id !== "61829de6e64b1b17688326f3" ||
          this.userp.role_id !== "6424071f50b77c6cf890ab0d" ||
          this.userp.role_id !== "6472bf9b1ba4b8a1604940c1" ||
          this.userp.role_id !== "611532b19c6e77ad0fd50c1c"
        ) {
          let selectionChannel = [];
         
          if (this.selectedCh != null) {
            if (this.selectedCh.channel_1 && this.showChannel1) {
              let data = {
                channel_type: 1,
                enable: this.selectedCh.channel_1
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 1,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_2 && this.showChannel2) {
              let data = {
                channel_type: 2,
                enable: this.selectedCh.channel_2
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 2,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_3 && this.showChannel3) {
              let data = {
                channel_type: 3,
                enable: this.selectedCh.channel_3
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 3,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_4 && this.showChannel4) {
              let data = {
                channel_type: 4,
                enable: this.selectedCh.channel_4
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 4,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_5 && this.showChannel5) {
              let data = {
                channel_type: 5,
                enable: this.selectedCh.channel_5
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 5,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_6 && this.showChannel6) {
              let data = {
                channel_type: 6,
                enable: this.selectedCh.channel_6
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 6,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_7 && this.showChannel7) {
              let data = {
                channel_type: 7,
                enable: this.selectedCh.channel_7
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 7,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_8 && this.showChannel8) {
              let data = {
                channel_type: 8,
                enable: this.selectedCh.channel_8
              }
              selectionChannel.push(data);

            }else{
              let data = {
                channel_type: 8,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_9 && this.showChannel9) {
              let data = {
                channel_type: 9,
                enable: this.selectedCh.channel_9
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 9,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_10 && this.showChannel10) {
              let data = {
                channel_type: 10,
                enable: this.selectedCh.channel_10
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 10,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_11 && this.showChannel11) {
              let data = {
                channel_type: 11,
                enable: this.selectedCh.channel_11
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 11,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_12 && this.showChannel12) {
              let data = {
                channel_type: 12,
                enable: this.selectedCh.channel_12
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 12,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_13 && this.showChannel13) {
              let data = {
                channel_type: 13,
                enable: this.selectedCh.channel_13
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 13,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_14 && this.showChannel14) {
              let data = {
                channel_type: 14,
                enable: this.selectedCh.channel_14
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 14,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_15 && this.showChannel15) {
              let data = {
                channel_type: 15,
                enable: this.selectedCh.channel_15
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 15,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_16 && this.showChannel16) {
              let data = {
                channel_type: 16,
                enable: this.selectedCh.channel_16
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 16,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_17 && this.showChannel17) {
              let data = {
                channel_type: 17,
                enable: this.selectedCh.channel_17
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 17,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_18 && this.showChannel18) {
              let data = {
                channel_type: 18,
                enable: this.selectedCh.channel_18
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 18,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_19 && this.showChannel19) {
              let data = {
                channel_type: 19,
                enable: this.selectedCh.channel_19
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 19,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_20 && this.showChannel20) {
              let data = {
                channel_type: 20,
                enable: this.selectedCh.channel_20
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 20,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_21 && this.showChannel21) {
              let data = {
                channel_type: 21,
                enable: this.selectedCh.channel_21
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 21,
                enable: false
              }
              selectionChannel.push(data);
            }
            this.user.channel_allow = selectionChannel;
          }
        }
        if (
          this.userp.role_id == "61829dc7e64b1b17688326d5" ||
          this.userp.role_id == "61829dd1e64b1b17688326df" ||
          this.userp.role_id == "6424071f50b77c6cf890ab0d" ||
          this.userp.role_id == "61829de6e64b1b17688326f3" ||
          this.userp.role_id == "6472bf9b1ba4b8a1604940c1" 
        ) {
          let selectionChannel = [];
         
          if (this.selectedCh != null) {
            if (this.selectedCh.channel_1 && this.showChannel1) {
              let data = {
                channel_type: 1,
                enable: this.selectedCh.channel_1
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 1,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_2 && this.showChannel2) {
              let data = {
                channel_type: 2,
                enable: this.selectedCh.channel_2
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 2,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_3 && this.showChannel3) {
              let data = {
                channel_type: 3,
                enable: this.selectedCh.channel_3
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 3,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_4 && this.showChannel4) {
              let data = {
                channel_type: 4,
                enable: this.selectedCh.channel_4
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 4,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_5 && this.showChannel5) {
              let data = {
                channel_type: 5,
                enable: this.selectedCh.channel_5
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 5,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_6 && this.showChannel6) {
              let data = {
                channel_type: 6,
                enable: this.selectedCh.channel_6
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 6,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_7 && this.showChannel7) {
              let data = {
                channel_type: 7,
                enable: this.selectedCh.channel_7
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 7,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_8 && this.showChannel8) {
              let data = {
                channel_type: 8,
                enable: this.selectedCh.channel_8
              }
              selectionChannel.push(data);

            }else{
              let data = {
                channel_type: 8,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_9 && this.showChannel9) {
              let data = {
                channel_type: 9,
                enable: this.selectedCh.channel_9
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 9,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_10 && this.showChannel10) {
              let data = {
                channel_type: 10,
                enable: this.selectedCh.channel_10
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 10,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_11 && this.showChannel11) {
              let data = {
                channel_type: 11,
                enable: this.selectedCh.channel_11
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 11,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_12 && this.showChannel12) {
              let data = {
                channel_type: 12,
                enable: this.selectedCh.channel_12
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 12,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_13 && this.showChannel13) {
              let data = {
                channel_type: 13,
                enable: this.selectedCh.channel_13
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 13,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_14 && this.showChannel14) {
              let data = {
                channel_type: 14,
                enable: this.selectedCh.channel_14
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 14,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_15 && this.showChannel15) {
              let data = {
                channel_type: 15,
                enable: this.selectedCh.channel_15
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 15,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_16 && this.showChannel16) {
              let data = {
                channel_type: 16,
                enable: this.selectedCh.channel_16
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 16,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_17 && this.showChannel17) {
              let data = {
                channel_type: 17,
                enable: this.selectedCh.channel_17
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 17,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_18 && this.showChannel18) {
              let data = {
                channel_type: 18,
                enable: this.selectedCh.channel_18
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 18,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_19 && this.showChannel19) {
              let data = {
                channel_type: 19,
                enable: this.selectedCh.channel_19
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 19,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_20 && this.showChannel20) {
              let data = {
                channel_type: 20,
                enable: this.selectedCh.channel_20
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 20,
                enable: false
              }
              selectionChannel.push(data);
            }
            if (this.selectedCh.channel_21 && this.showChannel21) {
              let data = {
                channel_type: 21,
                enable: this.selectedCh.channel_21
              }
              selectionChannel.push(data);
            }else{
              let data = {
                channel_type: 21,
                enable: false
              }
              selectionChannel.push(data);
            }
            this.user.channel_allow = selectionChannel;
          }
          
        }

        this.$emit("submit");
      }
    },
    clearOldData() {
      this.user.dataConfig = [];
      this.user.dataConfig = [];
      this.selectionChannel1 = [];
      this.selectedChannel1 = [];
      this.selectionChannel2 = [];
      this.selectedChannel2 = [];
      this.selectionChannel3 = [];
      this.selectedChannel3 = [];
      this.selectionChannel4 = [];
      this.selectedChannel4 = [];
      this.selectionChannel5 = [];
      this.selectedChannel5 = [];
      this.selectionChannel6 = [];
      this.selectedChannel6 = [];
      this.selectionChannel7 = [];
      this.selectedChannel7 = [];
      this.selectionChannel8 = [];
      this.selectedChannel8 = [];
      this.selectionChannel9 = [];
      this.selectedChannel9 = [];
      this.selectionChannel10 = [];
      this.selectedChannel10 = [];
      this.selectionChannel11 = [];
      this.selectedChannel11 = [];
      this.selectionChannel12 = [];
      this.selectedChannel12 = [];
      this.selectionChannel13 = [];
      this.selectedChannel13 = [];
      this.selectionChannel14 = [];
      this.selectedChannel14 = [];
      this.selectionChannel15 = [];
      this.selectedChannel15 = [];
      this.selectionChannel16 = [];
      this.selectedChannel16 = [];
      this.selectionChannel17 = [];
      this.selectedChannel17 = [];
      this.selectionChannel18 = [];
      this.selectedChannel18 = [];
      this.selectionChannel19 = [];
      this.selectedChannel19 = [];
      this.selectionChannel20 = [];
      this.selectedChannel20 = [];
      this.selectionChannel21 = [];
      this.selectedChannel21 = [];
      this.limitConfig = [];
      this.selectedCh = [];
      this.user.role_id = '';
    },
    async getLimitData() {
      if (!this.userp.currency_type) this.userp.currency_type = 1;
      let data;
      if(this.user._id){
        data = {
          currency_type: this.user.currency_type,
          _id: this.user._id,
          is_edit: true,
          parent_id: this.userp._id
        };
      }else{
        data = {
          currency_type: this.userp.currency_type,
          _id: this.userp._id,
          is_edit: false,
          parent_id: this.userp._id
        };
      }
      
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBetting",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },
    async correctSelectChanel(item) {
      
      for (let i in item) {
        if (item[i].channel_type == 1) {
          if(item[i].enable)
            this.selectedCh.channel_1 = true;
        }
        if (item[i].channel_type == 2) {
          if(item[i].enable)
          this.selectedCh.channel_2 = true;
        }
        if (item[i].channel_type == 3) {
          if(item[i].enable)
          this.selectedCh.channel_3 = true;
        }
        if (item[i].channel_type == 4) {
          if(item[i].enable)
          this.selectedCh.channel_4 = true;
        }
        if (item[i].channel_type == 5) {
          if(item[i].enable)
          this.selectedCh.channel_5 = true;
        }
        if (item[i].channel_type == 6) {
          if(item[i].enable)
          this.selectedCh.channel_6 = true;
        }
        if (item[i].channel_type == 7) {
          if(item[i].enable)
          this.selectedCh.channel_7 = true;
        }
        if (item[i].channel_type == 8) {
          if(item[i].enable)
          this.selectedCh.channel_8 = true;
        }
        if (item[i].channel_type == 9) {
          if(item[i].enable)
          this.selectedCh.channel_9 = true;
        }
        if (item[i].channel_type == 10) {
          if(item[i].enable)
          this.selectedCh.channel_10 = true;
        }
        if (item[i].channel_type == 11) {
          if(item[i].enable)
          this.selectedCh.channel_11 = true;
        }
        if (item[i].channel_type == 12) {
          if(item[i].enable)
          this.selectedCh.channel_12 = true;
        }
        if (item[i].channel_type == 13) {
          if(item[i].enable)
          this.selectedCh.channel_13 = true;
        }
        if (item[i].channel_type == 14) {
          if(item[i].enable)
          this.selectedCh.channel_14 = true;
        }
        if (item[i].channel_type == 15) {
          if(item[i].enable)
          this.selectedCh.channel_15 = true;
        }
        if (item[i].channel_type == 16) {
          if(item[i].enable)
          this.selectedCh.channel_16 = true;
        }
        if (item[i].channel_type == 17) {
          if(item[i].enable)
          this.selectedCh.channel_17 = true;
        }
        if (item[i].channel_type == 18) {
          if(item[i].enable)
          this.selectedCh.channel_18 = true;
        }
        if (item[i].channel_type == 19) {
          if(item[i].enable)
          this.selectedCh.channel_19 = true;
        }
        if (item[i].channel_type == 20) {
          if(item[i].enable)
          this.selectedCh.channel_20 = true;
        }
        if (item[i].channel_type == 21) {
          if(item[i].enable)
          this.selectedCh.channel_21 = true;
        }
      }

      // this.selectedChannelu1 = this.selectionChannelu1;
      // this.selectedChannelu2 = this.selectionChannelu2;
      // this.selectedChannelu3 = this.selectionChannelu3;
      // this.selectedChannelu4 = this.selectionChannelu4;
    },
    currencyFormatKh(num) {
      // num = parseFloat(num);
      return parseInt(num).toLocaleString("en");
    },
  },
};
</script>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot{
  box-shadow: none!important;
}
.input-group .v-input__slot{
  width:100%!important;
}
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot{
  margin-top:-9px!important;
}
</style>
